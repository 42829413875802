import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerSowHistory,
  PlayerSowHistoryVariables,
} from './__generated__/PlayerSowHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerSowHistory(
    $after: String
    $before: String
    $desc: Boolean
    $eventType: String
    $first: Int
    $from: OffsetDateTime
    $last: Int
    $orderBy: PlayerComplianceHistorySearchOrder
    $playerId: ID!
    $to: OffsetDateTime
  ) {
    viewer {
      id
      playerComplianceHistory(
        after: $after
        before: $before
        desc: $desc
        eventType: $eventType
        first: $first
        from: $from
        last: $last
        orderBy: $orderBy
        playerId: $playerId
        to: $to
      ) {
        edges {
          node {
            uuid
            playerId
            playerUUID
            playerGlobalId
            eventType
            processedAt
            ... on PlayerPepStatusSetItem {
              isPep
              initiator {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on SourceOfWealthSubmittedItem {
              sourceOfWealth {
                fundingOptions
                salaryLowerBound
                salaryUpperBound
                expendingLowerBound
                expendingUpperBound
                otherFundingDescription
                nationality
              }
              amlStatus
            }
            ... on SourceOfWealthVerifiedItem {
              decision
              initiator {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on AmlRiskLevelChangedItem {
              amlRiskLevel
              reason
              initiator {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
            ... on FundingDocumentsRequestedItem {
              requestedFundingDocuments
              initiator {
                ... on PlayerInitiator {
                  player {
                    id
                  }
                }
                ... on AgentInitiator {
                  agent {
                    email
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerSowHistory(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const [{ data, fetching }, refresh] = useQuery<
    PlayerSowHistory,
    PlayerSowHistoryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.playerComplianceHistory.pageInfo,
    ),
    history: data?.viewer.playerComplianceHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
