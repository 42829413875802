import React from 'react';

import formatMoney from '@/utils/formatter/formatMoney';
import { PlayerSowHistory_viewer_playerComplianceHistory_edges_node } from './__generated__/PlayerSowHistory';

const extractSow = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => ('sourceOfWealth' in row && row.sourceOfWealth) || null;

export const renderFundingOptions = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  const sow = extractSow(row);
  if (sow?.fundingOptions) {
    return <li>Funding Options: {sow.fundingOptions.join(', ')}</li>;
  }
  return null;
};

export const renderDecision = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  if ('decision' in row && row.decision) {
    return <li>Decision: {row.decision}</li>;
  }
  return null;
};

export const renderAmlStatus = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  if ('amlStatus' in row && row.amlStatus) {
    return <li>AML Status: {row.amlStatus}</li>;
  }
  return null;
};

export const renderSalaryLowerBound = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  const sow = extractSow(row);
  if (sow?.salaryLowerBound) {
    return (
      <li>
        Salary Bound: {formatMoney(sow.salaryLowerBound)}
        {sow.salaryUpperBound ? ` - ${formatMoney(sow.salaryUpperBound)}` : ''}
      </li>
    );
  }
  return null;
};

export const renderExpendingLowerBound = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  const sow = extractSow(row);
  if (sow?.salaryLowerBound) {
    return (
      <li>
        Expending Bound: {formatMoney(sow.expendingLowerBound)}
        {sow?.expendingUpperBound
          ? ` - ${formatMoney(sow?.expendingUpperBound)}`
          : ''}
      </li>
    );
  }
  return null;
};

export const renderNationality = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  const sow = extractSow(row);
  if (sow?.nationality) {
    return <li>Nationality: {sow.nationality}</li>;
  }
  return null;
};

export const renderOtherFundingDescription = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  const sow = extractSow(row);
  if (sow?.otherFundingDescription) {
    return <li>Description: {sow.otherFundingDescription}</li>;
  }
  return null;
};

export const renderRequestedFundingOptions = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  if (
    'requestedFundingDocuments' in row &&
    row.requestedFundingDocuments.length
  ) {
    return (
      <li>Requested documents: {row.requestedFundingDocuments.join(', ')}</li>
    );
  }
  return null;
};

export const renderAmlRiskLevel = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  if ('amlRiskLevel' in row && row.amlRiskLevel) {
    return <li>New AML Risk Level: {row.amlRiskLevel}</li>;
  }
  return null;
};

export const renderReason = (
  row: PlayerSowHistory_viewer_playerComplianceHistory_edges_node,
) => {
  if ('reason' in row && row.reason) {
    return <li>AML Risk level Reason: {row.reason}</li>;
  }
  return null;
};
